import http from '@/http'
import authHeader from './auth-header'

class EdicoesService {
    async get() {
        const response = await http.get('colaborador/edicoes', { headers: authHeader() })
        return response
    }
    async getById(idCuco) {
        const response = await http.get(`colaborador/edicoes/${idCuco}`, { headers: authHeader() })
        return response
    }
    async getInscricao(idCuco, codeSchool) {
        const response = await http.get(`colaborador/edicoes/${idCuco}/escola/${codeSchool}/inscricoes`, { headers: authHeader() })
        return response
    }
    async getRelatorio(idCuco) {
        const response = await http.get(`colaborador/edicoes/${idCuco}/relatorio`, { headers: authHeader() })
        return response
    }
    async getCertificado(idCuco, idUser) {
        const response = await http.get(`colaborador/edicoes/${idCuco}/certificado/${idUser}`, { headers: authHeader() })
        return response
    }
    async getCertificado24(idCuco, idUser) {
        const response = await http.get(`colaborador/edicoes/${idCuco}/certificado/${idUser}/24`, { headers: authHeader() })
        return response
    }
    async getPremiacao(idCuco) {
        const response = await http.get(`colaborador/edicoes/${idCuco}/premiacao/professor`, { headers: authHeader() })
        return response
    }
    async getAllCertificadoDe(idCuco, de) {
        const response = await http.get(`colaborador/edicoes/${idCuco}/certificados/de/${de}/alunos`, { headers: authHeader() })
        return response
    }
}

export default new EdicoesService()
